import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/home' },
  { 
    path: '/home',
    component: () => import('../components/Home.vue'),
    redirect: '/index',
    children: [
      { path: '/index', component: () => import('../components/Index.vue'), meta:{ title: '首页'} },
      { path: '/about_us', component: () => import('../components/About_us.vue'), meta:{ title: '关于我们'}  },
      { path: '/design_concept', component: () => import('../components/Design_concept.vue'), meta:{ title: '设计理念'} },
      { path: '/products/:id', component: () => import('../components/Products.vue'), meta:{ title: '产品展示'} },
      { path: '/products', component: () => import('../components/Products.vue'), meta:{ title: '产品展示'} },
      { path: '/product/:id', component: () => import('../components/Product.vue'), meta:{ title: '产品展示'} },
      { path: '/technical_support', component: () => import('../components/Technical_support.vue'), meta:{ title: '技术支持'} },
      { path: '/customer_list', component: () => import('../components/Customer_list.vue'), meta:{ title: '客户合作名录'} },
      { path: '/partner', component: () => import('../components/Partner.vue'), meta:{ title: '合作伙伴'} },
      { path: '/contact_us', component: () => import('../components/Contact_us.vue'), meta:{ title: '联系我们'} },
      { path: '/technical_content/:id', component: () => import('../components/technical_content.vue'), meta:{ title: '技术支持'} },
    ],
  },

]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
